import { HomeOutlined } from "@mui/icons-material";
import PlaceIcon from '@mui/icons-material/Place';
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import WaitingParentIcon from '@mui/icons-material/SensorOccupied';
import ApprovedPartnerIcon from '@mui/icons-material/CheckCircleOutline';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Timelapse from '@mui/icons-material/Timelapse';

import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import GridViewIcon from '@mui/icons-material/GridView';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import SystemUpdate from '@mui/icons-material/SystemUpdateAlt';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import HelpIcon from '@mui/icons-material/HelpOutline';
import MapIcon from '@mui/icons-material/Map';
import { text } from "@fortawesome/fontawesome-svg-core";
 


 export const navigationItems = [
    {
      text: "Home",
      icon: <HomeOutlined />,
      link: "Home",
    },
    {
      // for coordinator

      text:"Willkommen",
      icon: <HomeOutlined />,
      link: "Home-Coordinator",
    },
    {
      text: "Stammdaten",
      icon: null,
      link: "",
    },   
    {
      text: "Regionen",
      icon: <PlaceIcon />,
      link: "Regions",
    },
    {
      text: "Partner",
      icon: null,
      link: "",
    },     
    {
      text: "Partner-Details",
      icon: <PeopleAltIcon />,
      link: "Partners",
    },  
    {
      text: "Wöchentliche Kapazitäten",
      icon: <Timelapse />,
      link: "ApprovedPartnersWeeklyHours",
    }, 
    {
      text: "Bewilligte Partner",
      icon: <ApprovedPartnerIcon />,
      link: "ApprovedPartners",
             
    },
    {
      text: "Wartende Partner",
      icon: <WaitingParentIcon />,
      link: "WaitingPartners",
    },
    {
      text: "Zahlungsmanagement",
      icon: null,
      link: "",
    },
    {
      text: "Bewilligte Zahlungen",
      icon: <ReceiptOutlinedIcon />,
      link: "PaidPayments",
    },  
    {
      text: "Wartende Zahlungen",
      icon: <AccountBalanceWalletIcon />,
      link: "WaitingPayments",
    },          
    {
      text: "Management",
      icon: null,
      link: "",
    }, 
    {
      text: "Control Pannel",
      icon: <GridViewIcon />,
      link: "ControlPannel",
    }, 
    {
      text: "Users Location (Map)",
      icon: <MapIcon />,
      link: "UsersLocation",
    },
    
    {
      text: "Audit Logs",
      icon: <ScatterPlotIcon/>,
      link: "AuditLogs",
    },    
    {
      text: "User Logins",
      icon: <PermIdentityIcon/>,
      link: "UserSystemLoginsMonitor",
    }, 
    {
      text: "System Updates",
      icon: <SystemUpdate/>,
      link: "SystemUpdates",
    },

    {
      text: "System Logs/Errors",
      icon: <ErrorOutlineIcon />,
      link: "SystemLogs",
    },
   
    {
      text: "Main",
      icon: null,
      link: "",
    }, 
    {
      text: "Dashboard",
      icon: <GridViewIcon />,
      link: "Dashboard",
    }, 
    {
      text: "Profil",
      icon: <PersonPinIcon />,
      link: "UserProfile",
    }, 
    {
      text: "Help",
      icon: <HelpIcon />,
      link: "Help",
    }, 
 
    
  ];

  export function filterNavigationItems(isAdmin, isManager, isCoordinator, passwordChanged) {
 

    //if(typeof (isAdmin) === 'undefined')
    //return [];
    if (!passwordChanged && !isAdmin && !isCoordinator && !isManager) {
      
        return [];
    }
    if(typeof (isManager) === 'undefined')
      isManager = false;
    if(typeof (isCoordinator) === 'undefined')
      isCoordinator = false;
    var filteredItems= navigationItems.filter(
      (item) =>
        
      !(item.link  === "Home" && !(isAdmin || isManager) ) &&  

      !(item.link  === "Home-Coordinator" && !(isCoordinator  ) ) &&  
      
      !(item.text  === "Stammdaten" && !(isAdmin|| isManager || isCoordinator)) &&            
      !(item.link  === "Regions" && !(isAdmin || isManager || isCoordinator)) &&

      !(item.text  === "Partner" && !(isAdmin || isManager || isCoordinator))  &&
      !(item.link  === "Partners" && !(isAdmin || isManager || isCoordinator)) &&
      !(item.link  === "ApprovedPartners" && !(isAdmin || isManager || isCoordinator)) &&
      !(item.link  === "ApprovedPartnersWeeklyHours" && !(isAdmin || isManager || isCoordinator)) &&
      
      !(item.link  === "WaitingPartners" && !(isAdmin || isManager|| isCoordinator)) &&
      
      !(item.text  === "Zahlungsmanagement" && !(isAdmin || isManager ))  &&
      !(item.text  === "Bezahlung" && !(isAdmin || isManager)) &&
      !(item.link  === "PaidPayments" && !(isAdmin || isManager)) &&
      !(item.link  === "WaitingPayments" && !(isAdmin || isManager)) &&
      
      !(item.text  === "Management" && !(isAdmin || isManager || isCoordinator)) &&
      !(item.link  === "UserManagement" && !(isAdmin || isManager || isCoordinator)) &&
      !(item.link  === "ControlPannel" && !(isAdmin || isManager || isCoordinator))  &&
      !(item.link  === "AuditLogs" && !(isAdmin || isManager || isCoordinator))  &&
      !(item.link  === "SystemUpdates" && !(isAdmin || isManager || isCoordinator)) &&
      !(item.link  === "SystemLogs" && !(isAdmin || isManager || isCoordinator)) &&
      !(item.link  === "UserSystemLoginsMonitor" && !(isAdmin || isManager || isCoordinator)) &&
      
      
      !(item.link  === "UsersLocation" && !(isAdmin || isManager || isCoordinator))  &&
      !(item.link  === "Help" && !(isAdmin || isManager || isCoordinator))           
      
    //Normal User ------------------------------------
        && !(item.text === "Main" && isAdmin )             
        && !(item.link === "UserProfile" && isAdmin)          
        && !(item.link === "Dashboard" && (isAdmin || !passwordChanged))     
    );

    return filteredItems;
  }
  